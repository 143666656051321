// Variables

// General

$sidebar-width: 260px;
$app-sidebar-transition: cubic-bezier(0.685, 0.0473, 0.346, 1);
// Collapsed

$sidebar-width-collapsed: 88px;

// Light color scheme

$sidebar-bg-light: $white;
$sidebar-shadow-light: 0px 0px 28px 0px rgba($primary, 0.08);
$sidebar-content-bg-light: transparent;
// Dark color scheme

// SALMON SUAVE
$sidebar-bg: linear-gradient(
  to bottom,
  #fa6c86 0%,
  #f9748f 19%,
  #fd868c 60%,
  #e4ae81 100%
) !important;

// SALMON FUERTE
//$sidebar-bg: linear-gradient(to top, #ff0844 0%, #ffb199 100%) !important;

//VIOLETA
/*$sidebar-bg: linear-gradient(
  135deg,
  #ce9ffc 10%,
  #502a91 100% 
) !important;*/ /*#7367f0 */ //$gray-900;

$sidebar-shadow: $box-shadow-layout-right;
$sidebar-content-bg: transparent;
