//  Core

.app-header {
  height: $header-height;
  padding: 0 $layout-spacer 0 calc($layout-spacer / 2);
  background: $header-bg;
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  z-index: 1111;
  transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);

  &--pane {
    display: flex;
    align-items: center;
  }

  // Navigation menu

  .header-nav-menu {
    flex: 2;
    display: flex;

    &>ul {
      list-style: none;
      margin: 0;
      padding: 0;

      &>li {
        display: inline-flex;
        margin: 0 4px;
        position: relative;

        .dropdown-arrow {
          transform: rotate(0deg);
          transition: $transition-base;
          margin-left: 8px;
          height: 16px;
          line-height: 16px;
          width: 10px;
        }

        .submenu-dropdown {
          position: absolute;
          top: 100%;
          left: 50%;
          width: 280px;
          margin-left: -140px;
          visibility: hidden;
          opacity: 0;
          padding-top: 15px;
          transition: $transition-base;
          z-index: 20;
          transform: translateX(-18px) translateY(-18px) scale(0.75);

          &--sm {
            width: 200px;
            margin-left: -100px;
          }

          &--lg {
            width: 420px;
            margin-left: -210px;
          }

          &--xl {
            width: 640px;
            margin-left: -320px;
          }

          &--xxl {
            width: 940px;
            margin-left: -470px;
          }
        }

        &>a {
          transition: $transition-base;
          padding: calc($spacer / 1.5) ($spacer * 1.5);
          display: flex;
          align-items: center;
        }

        &:hover {
          &>a {
            &>.dropdown-arrow {
              transform: rotate(-180deg);
            }
          }

          &>.submenu-dropdown {
            opacity: 1;
            transform: translateX(0px) translateY(0px) scale(1);
            visibility: visible;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(md) {
    .app-header--pane+.app-header--pane {
      transition: $transition-base;
      transform: scale(1);
      visibility: visible;
    }
  }

  &--shadow {
    box-shadow: $header-shadow;
  }

  &--opacity-bg {
    background: $header-bg-transparent;
  }

  .hamburger-inner,
  .hamburger-inner:after,
  .hamburger-inner:before {
    background: $primary !important;
  }

  .toggle-mobile-sidebar-btn {
    display: none;
  }

  &:hover {
    background: $header-bg !important;
  }
}

// Fixed header

@include media-breakpoint-up(lg) {
  .app-header-fixed {
    .app-header {
      position: fixed;
      left: $sidebar-width;
      right: 0;
      margin-left: 0;
    }
  }
}

// Fixed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-fixed:not(.app-header-fixed) {
    .app-header {
      margin-left: $sidebar-width;
    }
  }
}

// Collapsed sidebar

@include media-breakpoint-up(lg) {
  .app-sidebar-collapsed:not(.app-header-fixed) {
    .app-header {
      margin-left: $sidebar-width-collapsed;
      left: 0;
    }

    &.app-header-fixed {
      .app-header {
        left: $sidebar-width-collapsed;
        margin-left: 0;
      }
    }
  }

  .app-sidebar-collapsed {
    &.app-header-fixed {
      .app-header {
        left: $sidebar-width-collapsed;
      }
    }
  }
}

// Responsive

@include media-breakpoint-down(md) {
  .app-header {
    left: 0;
    padding: 0 calc($layout-spacer / 2);

    .toggle-mobile-sidebar-btn {
      display: block;
    }
  }
}