@import "../../responsive-mixins";

.app-page-title-reduced--first {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: center;
  align-items: center;

  @include xs() {
    flex-direction: column;
  }
}

