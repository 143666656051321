.executive-cards {
  .bg-marketing {
    background: $marketing;
  }

  .bg-production {
    background: $production;
  }

  .bg-finance {
    background: $finance;
  }

  .badge-marketing {
    color: #fff;
  }

  .badge-production {
    color: #fff;
  }
}