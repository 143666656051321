//  Core

@each $color, $value in $theme-colors {
  .popover-#{$color} {
    background: $value;

    .popover-body {
      color: color-yiq($value);
    }

    &.bs-popover-top {
      > .arrow {
        &::before {
          border-top-color: $popover-arrow-outer-color;
        }

        &::after {
          border-top-color: $value;
        }
      }
    }

    &.bs-popover-right {
      > .arrow {
        &::before {
          border-right-color: $popover-arrow-outer-color;
        }

        &::after {
          border-right-color: $value;
        }
      }
    }

    &.bs-popover-bottom {
      > .arrow {
        &::before {
          border-bottom-color: $popover-arrow-outer-color;
        }

        &::after {
          border-bottom-color: $value;
        }
      }
    }

    &.bs-popover-left {
      > .arrow {
        &::before {
          border-left-color: $popover-arrow-outer-color;
        }

        &::after {
          border-left-color: $value;
        }
      }
    }
  }
}
