@mixin xs {
  @media screen and (max-width: 575px) {
      @content;
  }
}

@mixin sm {
  @media screen and (min-width: 576px) and (max-width: 767px) {
      @content;
  }
}

@mixin md {
  @media screen and (min-width: 768px) and (max-width: 991px) {
      @content;
  }
}

@mixin lg {
  @media screen and (min-width: 992px) and (max-width: 1199px) {
      @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1200px) and (max-width: 1399px) {
      @content;
  }
}

@mixin xl {
  @media screen and (min-width: 1400px) {
      @content;
  }
}