// React Dropzone


.dropzone {
  position: relative;
  display: flex;
  flex-direction: column;
  outline: none !important;

  & * {
    outline: none !important;
  }
}

.dz-preview-single {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include border-radius($border-radius);
}

.dz-preview-cover {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include border-radius($border-radius);
}

.dz-preview-img {
  width: 100%;
  height: 100%;
  @include border-radius($border-radius);
  object-fit: cover;
}

.dz-message {
  z-index: 5;
  padding: ($spacer * 3) ($spacer);
  cursor: pointer;
  transition: $transition-base;
  text-align: center;
  color: $gray-600;
  border: 1px dashed $gray-400;
  @include border-radius($border-radius);
  background-color: $gray-100;
  font-size: $font-size-lg;
  order: -1;

  &:hover {
    border-color: $primary;
    color: $gray-800;
  }
}

.dropzone-single.dz-max-files-reached .dz-message {
  background: rgba($black, .9);

  &:hover {
    opacity: 1;
  }
}
