.fixButton {
  border-top-width: 0px;
  border-bottom-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  min-width: 165px;
  //background-color: white !important;
}
.fixMotion {
  background: rgba(255, 255, 255, 0.5);
  border-radius: 0.65rem;
  border: 1px solid rgba (7, 9, 25, 0.25);
}
.fixCenter{
  margin: auto;
}
.bold{
  font-weight: 600 !important;
}
