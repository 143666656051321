#ranking-page {
  .ranking-player-item {
    .ranking-player-item-name {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .ranking-list-wrapper {
    height: 32rem;
    max-height: 32rem;
    overflow: scroll;
  }
}