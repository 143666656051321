// Tabs animated

.tabs-animated {
  &>.nav-tabs {
    border: 0;

    .nav-item {
      .nav-link {
        border: 0 !important;
        position: relative;
        padding: calc($layout-spacer / 3) calc($layout-spacer / 2);
        margin: 0 calc($layout-spacer / 4) 0 0;
        color: $body-color;
        text-transform: none;
        font-size: $font-size-base;
        cursor: pointer;

        &::before {
          transform: scale(0);
          opacity: 1;
          width: 100%;
          left: 0;
          bottom: -2px;
          content: "";
          position: absolute;
          display: block;
          @include border-radius($border-radius-lg);
          background: $primary;
          transition: all 0.2s;
          height: 4px;
        }

        &.active,
        &:hover {
          color: $primary;
          border: 0;

          &::before {
            transform: scale(1);
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .nav-item-marketing {
      .nav-link {
        border: 0 !important;
        position: relative;
        padding: calc($layout-spacer / 3) calc($layout-spacer / 2);
        margin: 0 calc($layout-spacer / 4) 0 0;
        color: $body-color;
        text-transform: none;
        font-size: $font-size-base;
        cursor: pointer;

        &::before {
          transform: scale(0);
          opacity: 1;
          width: 100%;
          left: 0;
          bottom: -2px;
          content: "";
          position: absolute;
          display: block;
          @include border-radius($border-radius-lg);
          background: $transparent-smoke; //rgba(253, 253, 253, 0.6); //$whitesmoke; // roll back for old Nav
          transition: all 0.2s;
          height: 4px;
        }

        &.active,
        &:hover {
          color: $marketing !important;// !important; // roll back for old Nav

          span {
            color: $marketing !important;// !important; // roll back for old Nav
          }

          &::before {
            @include border-radius($border-radius-sm);
            opacity: 1;
          }
        }
      }
    }

    .nav-item-production {
      .nav-link {
        border: 0 !important;
        position: relative;
        padding: calc($layout-spacer / 3) calc($layout-spacer / 2);
        margin: 0 calc($layout-spacer / 4) 0 0;
        color: $body-color;
        text-transform: none;
        font-size: $font-size-base;
        cursor: pointer;

        &::before {
          transform: scale(0);
          opacity: 1;
          width: 100%;
          left: 0;
          bottom: -2px;
          content: "";
          position: absolute;
          display: block;
          @include border-radius($border-radius-lg);
          background: $transparent-smoke; //$whitesmoke; //$production;
          transition: all 0.2s;
          height: 4px;
        }

        /*&.active,
        &:hover {
          color: $production;
          border: 0;

          &::before {
            transform: scale(1);
          }
        }

        &:last-child {
          margin-right: 0;
        }*/
        &.active,
        &:hover {
          color: $production !important;// !important; // roll back for old Nav

          span {
            color: $production !important;// !important; // roll back for old Nav
          }

          &::before {
            @include border-radius($border-radius-sm);
            opacity: 1;
          }
        }
      }
    }

    .nav-item-finance {
      .nav-link {
        border: 0 !important;
        position: relative;
        padding: calc($layout-spacer / 3) calc($layout-spacer / 2);
        margin: 0 calc($layout-spacer / 4) 0 0;
        color: $body-color;
        text-transform: none;
        font-size: $font-size-base;
        cursor: pointer;

        &::before {
          transform: scale(0);
          opacity: 1;
          width: 100%;
          left: 0;
          bottom: -2px;
          content: "";
          position: absolute;
          display: block;
          @include border-radius($border-radius-lg);
          background: $transparent-smoke; // $whitesmoke; //$finance;
          transition: all 0.2s;
          height: 4px;
        }

        /*&.active,
        &:hover {
          color: $finance;
          border: 0;

          &::before {
            transform: scale(1);
          }
        }

        &:last-child {
          margin-right: 0;
        }*/
        &.active,
        &:hover {
          color: $finance !important; // roll back for old Nav

          span {
            color: $finance !important; // roll back for old Nav
          }

          &::before {
            @include border-radius($border-radius-sm);
            opacity: 1;
          }
      }
    }
    }
  }

  &.tabs-bordered {
    &>.nav-tabs {
      border-bottom: $card-border-color solid 1px;
      padding: calc($layout-spacer / 2);

      .nav-item {
        .nav-link {
          margin-bottom: 0;
        }
      }
    }
  }
}

.tabs-animated-line {
  .nav-tabs {
    padding: 0 5px;

    .nav-link {
      cursor: pointer;

      span {
        width: 100%;
      }
    }
  }
}

.tabs-animated-shadow {
  &>.nav-tabs {
    position: relative;
    z-index: 9;

    .nav-item {
      .nav-link {
        padding: calc($layout-spacer / 3) calc($layout-spacer / 2);
        margin-bottom: calc($layout-spacer / 4);
        cursor: pointer;

        span {
          position: relative;
          z-index: 5;
          display: inline-block;
          width: 100%;
        }

        &::before {
          height: 100%;
          top: 0;
          z-index: 4;
          bottom: auto;
          box-shadow: 0 16px 26px -10px rgba($primary, 0.56),
            0 4px 25px 0px rgba(0, 0, 0, 0.12),
            0 8px 10px -5px rgba($primary, 0.2);
          @include border-radius(calc($border-radius-xs / 2));
          opacity: 0.5;
        }

        &.active,
        &:hover {
          color: $white;// !important; roll back for old Nav

          span {
            color: $white;// !important; roll back for old Nav
          }

          &::before {
            @include border-radius($border-radius-lg);
            opacity: 1;
          }
        }
      }

      .nav-item:last-child {
        .nav-link {
          margin-right: 0;
        }
      }
    }
  }
}

.tabs-rm-padding {
  &>.nav.nav-tabs {
    padding: 0 !important;

    .nav-item .nav-link {
      margin-bottom: 0 !important;
    }
  }
}

/*.marketing-bg {
  background: rgba(9, 255, 210, 0.15);
  
}
.production-bg {
  background: rgba(255, 148, 9, 0.25);
}
.finance-bg {
  background: rgba(75, 9, 255, 0.15);
}
*/

.marketing-bg {
  background: #7fcbc0 //#7fcbc0; //rgb(101, 226, 212);
}

.production-bg {
  background: #f68ea1;
}

.finance-bg {
  background: #bc97ef //rgb(162, 109, 236);
}

.marketing-tip-bg {
  background: #91cec4; //rgb(101, 226, 212);
}

.production-tip-bg {
  background: #f7acb9;
}

.finance-tip-bg {
  background :#bc97ef;
}



.side-nav-bg {
  background: #f36985;
}

.marketing {
  background: $marketing;
}

.production {
  background: $production;
}

.finance {
  background: $finance;
}