//  Core
.accordion {
  padding: 2rem 25rem 2rem 25rem;
  & > .card-box {
    & > .card {
      box-shadow: none !important;

      & > .card-header {
        padding: 0;
        border-bottom: 0;

        & > .panel-title .accordion-toggle,
        & > .panel-title {
          width: 100%;
        }

        .btn {
          display: block;
          width: 100%;
          text-align: left;
          border-bottom: transparent solid 1px;
          //background: $gray-200;

          &:hover {
            //background: $gray-100;
            color: $gray-800;
          }

          &:not(:disabled):hover {
            transform: translateY(0px);
          }

          .accordion-icon {
            transform: rotate(90deg);
            transition: $transition-base;
          }
        }
      }

      .panel-body {
        padding: 0;
      }
    }

    &.panel-open {
      & > .card {
        & > .card-header {
          .btn {
            border-bottom-color: $card-border-color;
            background: $card-bg;

            .accordion-icon {
              transform: rotate(180deg);
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .accordion {
    padding: 2rem 9rem 2rem 9rem;
  }
}

@include media-breakpoint-down(md) {
  .accordion {
    padding: 2rem 7rem 2rem 7rem;
  }
}

@include media-breakpoint-down(sm) {
  .accordion {
    padding: 2rem 2rem 2rem 2rem;
  }
}

@include media-breakpoint-down(xs) {
  .accordion {
    padding: 0rem 0.5rem 0rem 0.5rem;
  }
}
