//  Core

.popover {
  box-shadow: $box-shadow-sm;
}

.popover-custom-lg {
  & > .popover {
    width: $popover-max-width * 1.7;
    max-width: $popover-max-width * 1.7;
  }
}

.popover-custom-md {
  & > .popover {
    width: $popover-max-width * 1.5;
    max-width: $popover-max-width * 1.5;
    border-radius: 25px;
  }
}

.popover-custom-xl {
  & > .popover {
    width: $popover-max-width * 2;
    max-width: $popover-max-width * 2;
  }
}

.popover-custom-wrapper {
  & > .popover {
    .popover-body {
      padding: 0;
    }
  }
}

.popover-max-width {
  & > .popover {
    max-width: none;
  }
}
