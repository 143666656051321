//  Core

.nav {
  &.nav-lg {
    .nav-item {
      .nav-link {
        padding: ($nav-link-padding-y * 1.5) ($nav-link-padding-x * 1.5);
        @include border-left-radius(0);
        position: relative;

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          width: 4px;
          content: '';
          transition: $transition-base;
          opacity: 0;
          height: 0px;
          visibility: hidden;
        }

        &.active,
        &:hover {
          &::before {
            opacity: 1;
            height: 100%;
            visibility: visible;
          }
        }
      }
    }
  }
}
