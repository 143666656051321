//  Core

$tweak: 78px; //-214px;
$tweak2: 0; // -42px;

.app-drawer-content {
  position: fixed;
  right: $tweak2;
  top: $tweak;
  z-index: 1160;
  height: 100vh;
  transform: translate($header-drawer-width);
  transition: $header-drawer-transition;
  box-shadow: 0 0 0 0 transparent;
  width: $header-drawer-width;
  background: #e43580; // $white;

  .close-drawer-btn {
    transition: $transition-base;
    position: absolute;
    left: -19px;
    width: 38px;
    height: 38px;
    margin: 0;
    top: 19px;
    padding: 0;
    border: 0;
    background: $white;
    color: $white;
    box-shadow: $box-shadow-sm;
    z-index: 1155;
    opacity: 0;
    visibility: hidden;
  }
}

.app-drawer-wrapper {
  padding: calc($spacer / 3.5) 0 calc($spacer / 3.5) ($spacer);
  margin: 0 0 0 ($spacer * 2);
  height: 220px;
  display: flex;
  align-items: center;
  position: fixed;
  border-radius: 1rem;
  right: -22px;
  z-index: 999;
  width: 60px;
  top: 39%;

  @include media-breakpoint-down(lg) {
    padding: calc($spacer / 3.5) 0 calc($spacer / 3.5) ($spacer);
    margin: 0 0 0 $spacer;
  }
}

// Open header drawer section

.header-drawer-open {
  .app-drawer-content {
    transform: translate(0);
    box-shadow: $header-drawer-open-box-shadow;
  }

  .close-drawer-btn {
    opacity: 1;
    visibility: visible;
    @include border-radius(100%);
  }
}

// Drawer overlay

.app-drawer-overlay {
  width: 0;
  height: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(7, 9, 25, 0.4);
  background: linear-gradient(90deg,
      rgba(7, 9, 25, 0.1) 25%,
      rgba(7, 9, 25, 0.6) 100%);
  z-index: 1112;

  &.is-active {
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 100vh;
    left: $tweak2;
    top: $tweak;
  }
}

@include media-breakpoint-down(xs) {
  .app-drawer-overlay {
    height: 230vh;
    top: -259px;
    width: 90%;
  }

  .app-drawer-overlay.is-active {
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 230vh;
    left: 0 !important;
    top: -288px;
  }

  .app-drawer-content {
    opacity: 1;
    visibility: visible;
    width: 80% !important;
    height: 230vh;
    left: 64px;
    top: 78px !important;
  }
}

@include media-breakpoint-down(md) {
  .app-drawer-overlay {
    height: 230vh;
    top: -259px;
    width: 90%;
  }

  .app-drawer-overlay.is-active {
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 230vh;
    left: 0px;
    top: -288px;
  }

  .app-drawer-content {
    position: fixed;
    right: $tweak2;
    top: 78px;
    z-index: 1160;
    height: 100vh;
    transform: translate($header-drawer-width);
    transition: $header-drawer-transition;
    box-shadow: 0 0 0 0 transparent;
    width: $header-drawer-width;
    background: #e43580; // $white;
  }
}

/*@include media-breakpoint-down(xl) {
  .app-drawer-overlay {
    height: 230vh;
    top: -259px;
    width: 90%;
  }
  .app-drawer-overlay.is-active {
    opacity: 1;
    visibility: visible;
    width: 100vw;
    height: 230vh;
    left: -42px;
    top: -288px;
  }
  .app-drawer-content {
    position: fixed;
    right: $tweak2;
    top: $tweak;
    z-index: 1160;
    height: 100vh;
    transform: translate($header-drawer-width);
    transition: $header-drawer-transition;
    box-shadow: 0 0 0 0 transparent;
    width: $header-drawer-width;
    background: $white;
  }
}*/

/*position: fixed;
    right: -42px;
    top: -214px;
    z-index: 1160;
    height: 100vh;
    transform: translate(450px);
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
    box-shadow: 0 0 0 0 transparent;
    no width: 450px; 
    background: #fff;*/

// NOT DELETE: REFERENCE NEEDED
/* @include media-breakpoint-down(md) {
  .app-drawer-wrapper,
  .app-drawer-overlay,
  .app-drawer-content {
    display: none;
  }
}*/